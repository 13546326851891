@import "../rgbstyle/rgbstyle-mixins";
@import "../rgbstyle/rgbstyle-variables";

.el-home-lista-categorias {
    position: relative;
    padding: 40px 0 80px;
    background-position: center;
    background-repeat: repeat-y;
    z-index: 1;
    @include desktop {
        padding: 80px 0 50px;
    }

    .introducao {
        margin-bottom: 25px;
        display: block;
        float: left;
        width: 100%;
        @include desktop {
            width: 25%;
            display: inline-block;
        }

        h1.v1 {
            margin-bottom: 10px;
            font-size: 23px;
            color: #757575;
            font-family: "Lato";
            font-weight: 300;
            font-type: "normal";
            text-transform: uppercase;
            width: 100%;
            display: block;
            float: left;
            text-align: center;

            strong {
                color: $color_destaque;
                font-weight: 700;
                font-type: "normal";
                width: 100%;
                display: block;
            }

            @include desktop {
                text-align: left;
            }
        }
    }

    .itens-container {
        width: 100%;
        display: block;
        float: left;
        @include desktop {
            width: 75%;
            display: inline-block;
        }
    }

    .itens {
        margin: 0 auto;

        .item {
            padding: 0 20px;
            text-align: center;
            position: relative;
            cursor: pointer;

            .titulo-container {
                .titulo {
                    margin-top: 20px;
                    font-size: 18px;
                    color: $color;
                    font-family: "Lato";
                    font-weight: 300;
                    font-type: "normal";
                    width: 100%;
                    display: block;

                    b {
                        text-transform: uppercase;
                        color: $color_destaque;
                        font-weight: 700;
                        font-type: "normal";
                        width: 100%;
                        display: block;
                    }
                }

                p {
                    line-height: 1.25;
                }
            }

            .btn {
                display: inline-block;
                font-size: 17px;
                line-height: 1.25;
                background-color: transparent;
                color: #5f5f5f;
                padding: 10px 40px;
                transition: 0.2s;
                margin: 20px 0;
                background: #f5f5f5;
                text-transform: uppercase;

                &:hover {
                    background: $color_destaque;
                    color: #fff;
                }
            }

            img {
                max-width: 100%;
                margin: 0 auto;

                &.hover {
                    display: none;
                }
            }

            &:hover {
                .titulo {
                    color: $color_destaque;
                }

                img {
                    display: none;

                    &.hover {
                        display: block;
                    }
                }
            }
        }
    }

    .container {
        position: relative;
    }

    .slick-slide {
        margin: 0;
    }

    .slick-list {
        margin: 0;
    }

    @include desktop {
        .slick-slide {
            margin: 0;
        }
        .slick-list {
            margin: 0;
        }
    }

    .slick-arrows {
        position: absolute;
        right: 10px;
        top: 0;
        display: flex;
        @include desktop {
            right: 0;
        }

        a {
            margin-left: 10px;
        }
    }

    .slick-dots {
        position: absolute;
        bottom: -20px;
        left: 0;
        width: 100%;
        text-align: center;

        li {
            display: inline-block;
            width: 14px;
            height: 14px;
            border-radius: 50%;
            border: none;
            text-indent: -9999px;
            position: relative;
            overflow: hidden;
            background: $color_destaque;
            margin: 0 3px;
            cursor: pointer;

            &.slick-active {
                background: $color_destaque2;
            }
        }
    }


}