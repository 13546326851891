/* Fontes
-------------------------------------------------- */
$font-family: 'Lato', sans-serif;
// $font-family-pt: 'PT Sans', sans-serif;
// $font-family-slabo: 'Slabo 27px', serif;
// $font-family-oswald: 'Oswald', sans-serif;
// $font-family-roboto: 'Roboto', sans-serif;
// $font-family-raleway: 'Raleway', sans-serif;
// $font-family-open-sans: 'Open Sans', sans-serif;
// $font-family-merriweather: 'Merriweather', serif;
// $font-family-montserrat: 'Montserrat', sans-serif;
// $font-family-source-pro: 'Source Sans Pro', sans-serif;
// $font-family2: Trebuchet MS,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Tahoma,sans-serif;
$font-size-mobile: 15;
$font-size: 18;
// @import "fontes/fontawesome/fontawesome";
// @import "fontes/fontawesome/fa-regular";
// @import "fontes/fontawesome/fa-solid";
// @import "fontes/fontawesome/fa-brands";
// @import "fontes/lato"; //300, 400, 700

/* Cores
-------------------------------------------------- */
$color: #131313;
$color_headings: #0e71b8;

$color_destaque: #0e71b8;
$color_destaque2: #0e71b8;
$menu_altura_mobile: 50px;
$menu_altura_desktop: 180px;